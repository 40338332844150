<template>
    <div :class="bg +' '+ color" style="padding:30px 0px">
        <v-container>
            <v-row>
                <v-col cols="12" class="text-center">
                    <font-awesome-icon :icon="['fas', product.src]" class="fa-2x black--text mb-3" />
                    <h4>{{ product.name }}</h4>
                    <div class="mt-5" v-if="bg!='bg-transparent'">
                        <v-btn :href="'#/'+product.link" color="orange"
                            class="white--text px-5 m-2 elevation-0">Open</v-btn>
                    </div>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
    export default {
        props: ['product', 'color', 'bg']
    }
</script>

<style>

</style>